// https://bootswatch.com/darkly/

body.es-theme-darkly {
  $theme-primary: #375a7f;
  $theme-primary-border: $theme-primary;
  $theme-primary-hover-bg: #161616;
  $theme-primary-hover-border: #151515;

  $theme-accordion-item-bg: #222;
  $theme-accordion-button-bg: #ebeff2;
  $theme-accordion-button-color: #325172;
  $theme-accordion-collapsed-button-bg: $theme-accordion-item-bg;
  $theme-accordion-collapsed-button-color: #fff;

  $theme-card-bg: #303030;
  $theme-card-border-color: rgba(0,0,0,.125);

  $theme-nav-link-color: rgba(255,255,255,.55);
  $theme-nav-link-color-hover: #fff;

  --bs-green: #4bbf73;
  --bs-red: #d9534f;
  --bs-body-bg: #222;
  --bs-body-color: #fff;
  --bs-light-rgb: 48,48,48;
  --bs-primary: $theme-primary;
  --bs-primary-rgb: 55,90,127;
  --bs-secondary-color: rgb(173, 181, 189);

  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  a {
    color: $theme-primary;
  }

  .accordion-button {


    &.collapsed {
      background-color: $theme-accordion-collapsed-button-bg;
      color: $theme-accordion-collapsed-button-color;

      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }
    }

    &:not(.collapsed) {
      background-color: $theme-accordion-button-bg;
      color: $theme-accordion-button-color;
    }
  }

  .accordion-item {
    background-color: $theme-accordion-item-bg;
  }

  .border-primary {
    border-color: $theme-primary-border!important;
  }

  .btn-light {
    color: #fff;
    background-color: #303030;
    border-color: #303030;
  }

  .card {
    background-color: $theme-card-bg;
    border-color: $theme-card-border-color;

    .border-top,
    .border-end {
      border-color: $theme-card-border-color !important;
    }
  }

  .dropdown-item {
    &:active {
      background-color: $theme-primary;
    }

    &:hover {
      background-color: #375a7f;
    }
  }

  .es-date-picker {
    .indicator {
      &.text-muted {
        color: $gray-800 !important;
      }
    }
  }

  .form-check-input {
    &:checked {
      background-color: $theme-primary;
      border-color: $theme-primary;
    }
  }

  .form-floating {
    > label {
      color: #444;
    }
  }

  .navbar {
    background-color: $theme-primary;
  }

  .navbar-brand {
    color: $white;
  }

  .navbar-light {
    .navbar-nav {
      .nav-link {
        color: $theme-nav-link-color;

        &.active, &:hover {
          color: $theme-nav-link-color-hover;
        }
      }
    }

    .btn-outline-secondary {
      border-color: rgba(255,255,255,.55);
      color: rgba(255,255,255,.55);

      &:hover {
        background-color: rgba(255,255,255,.25);
        border-color: #fff;
        color: #fff;
      }
    }
  }

  .nav-item {
    margin-right: 2rem;
  }

  .nav-link {
    color: $theme-nav-link-color;

    &:hover {
      color: $theme-nav-link-color-hover;
    }
  }

  .nav-pills {
    .nav-link {
      color: #fff;

      &.active {
        background-color: $theme-primary;
        color: #fff;
      }
    }
  }

  .offcanvas {
    background-color: var(--bs-body-bg);
  }

  .btn-primary {
    background-color: $theme-primary;
    border-color: $theme-primary-border;

    &:hover {
      background-color: $theme-primary-hover-bg;
      border-color: $theme-primary-hover-border;
    }
  }

  .btn-outline-primary {
    border-color: $theme-primary;
    color: $theme-primary;

    &:hover {
      color: #fff;
      background-color: $theme-primary;
    }
  }
}

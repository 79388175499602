.es-date-picker {
  .date-list {
    width: 100%;

    @media(min-width: 768px) {
      width: auto;
    }

    .card {
      font-size: .8rem;
    }
  }

  .indicator {
    padding-left: 1px;
    padding-right: 1px;
  }

  .indicator-icon {
    font-size: .4rem;
  }
}

@import 'variables';

@import 'node_modules/bootstrap/scss/bootstrap.scss';

@import 'cursors';
@import 'dates';
@import 'datepicker';
@import 'forms';
@import 'services';
@import 'sidebar';
@import 'validation';

@import 'root';
@import 'themes';

.es-spinner {
  &.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: rgba(var(--bs-light-rgb), .5);

    z-index: 1040;
  }
}

// https://bootswatch.com/journal/

body.es-theme-lux {
  $theme-primary: #1a1a1a;
  $theme-primary-border: $theme-primary;
  $theme-primary-hover-bg: #161616;
  $theme-primary-hover-border: #151515;

  $theme-accordion-button-bg: #e8e8e8;

  $theme-font-size-sm: .875rem;

  $theme-nav-link-color: rgba(255,255,255,.55);
  $theme-nav-link-color-hover: #fff;

  --bs-green: #4bbf73;
  --bs-red: #d9534f;
  --bs-primary: $theme-primary;
  --bs-primary-rgb: 26,26,26;

  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 200;
  letter-spacing: 1px;

  a {
    color: $theme-primary;
  }

  .accordion-button {
    &:not(.collapsed) {
      background-color: $theme-accordion-button-bg;
      color: $theme-primary
    }
  }

  .border-primary {
    border-color: $theme-primary-border!important;
  }

  .btn {
    font-size: $theme-font-size-sm;
    text-transform: uppercase;

    border-radius: 0;
  }

  .dropdown-item {
    &:active {
      background-color: $theme-primary;
    }
  }

  .form-check-input {
    &:checked {
      background-color: $theme-primary;
      border-color: $theme-primary;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: uppercase;
    letter-spacing: 3px;
  }

  .navbar {
    background-color: $theme-primary;
    font-weight: 600;
    font-size: $theme-font-size-sm;
    text-transform: uppercase;
  }

  .navbar-light {
    .navbar-brand {
      color: $theme-nav-link-color;
    }

    .navbar-nav {
      .nav-link {
        color: $theme-nav-link-color;

        &.active, &:hover {
          color: $theme-nav-link-color-hover;
        }
      }
    }

    .btn-outline-secondary {
      border-color: rgba(255,255,255,.55);
      color: rgba(255,255,255,.55);

      &:hover {
        background-color: rgba(255,255,255,.25);
        border-color: #fff;
        color: #fff;
      }
    }
  }

  .nav-item {
    margin-right: 2rem;
  }

  .nav-link {
    color: $theme-nav-link-color;

    &:hover {
      color: $theme-nav-link-color-hover;
    }
  }

  .nav-pills {
    .nav-link {
      color: $theme-primary;

      &.active {
        background-color: $theme-primary;
        border-radius: 0;
        color: #fff;
      }
    }
  }

  .btn-primary {
    background-color: $theme-primary;
    border-color: $theme-primary-border;

    &:hover {
      background-color: $theme-primary-hover-bg;
      border-color: $theme-primary-hover-border;
    }
  }

  .btn-outline-primary {
    border-color: $theme-primary;
    color: $theme-primary;

    &:hover {
      color: #fff;
      background-color: $theme-primary;
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Goudy+Bookletter+1911&display=swap');

@import 'themes/default';
@import 'themes/darkly';
@import 'themes/fusion';
@import 'themes/journal';
@import 'themes/lux';
@import 'themes/minty';
@import 'themes/pulse';

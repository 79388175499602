#sidebar {
  border-left: 0 none;
}

@media(max-width: 767.98px) {
  #sidebar {
    background-color: transparent;
    top: 56px;
  }
}

@media(min-width: 768px) {
  #sidebar {
    position: relative;
    width: 33.33333333%;
    top: 54px;

    visibility: visible;
    transform: translateX(0);

    .card {
      &.sticky-md-top {
        top: 2rem;
      }
    }
  }
}


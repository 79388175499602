.es-date-picker {
  .indicator {
    &.text-muted {
      color: $gray-300 !important;
    }
  }
}

@include media-breakpoint-down(sm) {
  .es-dates {
    .slot-list-afternoon,
    .slot-list-evening {
      border-top: 1px solid $border-color;
    }
  }
}

@include media-breakpoint-up(md) {
  .es-dates {
    .slot-list {
      flex-shrink: 0;
      width: calc(100% / 3);
    }

    .slot-list-morning,
    .slot-list-afternoon {
      border-right: 1px solid $border-color;
    }
  }
}

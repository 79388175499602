body.es-theme-fusion {
  $white:   #fff;
  $blue:    #007087;
  $yellow:  #d7d19d;

  $theme-primary: $blue;
  $theme-alt: #d0e2e5;

  $link-hover: $yellow;

  font-family: 'Goudy Bookletter 1911', serif;

  a {
    color: $theme-primary;
    transition: color 300ms ease-in-out;
  }

  .accordion-button {
    &:not(.collapsed) {
      background-color: $theme-alt;
      color: $theme-primary;
    }
  }

  .bg-light {
    background-color: $theme-alt !important;
  }

  .btn-primary {
    background-color: $theme-primary;
    border-color: $theme-primary;
  }

  .form-check-input {
    &:checked {
      background-color: $theme-primary;
      border-color: $theme-primary;
    }
  }

  .navbar {
    background-color: $theme-primary;
    font-family: 'Catamaran', sans-serif;

    a {
      color: $white !important;

      &:hover {
        color: $link-hover !important;
      }
    }
  }

  .text-primary {
    color: $theme-primary !important;
  }
}

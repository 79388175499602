.form-check-lg {
  .form-check-input {
    width: 2.5em;
    height: 2.5em;
  }
}

.form-check-input-rounded[type=checkbox] {
  border-radius: 50%;
}

// https://bootswatch.com/minty/

body.es-theme-minty {
  $theme-primary: #78c2ad;
  $theme-primary-hover: #66a593;

  --bs-green: $theme-primary;
  --bs-red: #F3969A;
  --bs-primary: $theme-primary;
  --bs-primary-rgb: 120, 194, 173;

  a {
    color: $theme-primary;
  }

  .accordion-button {
    &:not(.collapsed) {
      background-color: #CCE8E0;
      color: $theme-primary
    }
  }

  .border-primary {
    border-color: $theme-primary!important;
  }

  .dropdown-item {
    &:active {
      background-color: $theme-primary;
    }
  }

  .form-check-input {
    &:checked {
      background-color: $theme-primary;
      border-color: $theme-primary;
    }
  }

  .navbar {
    background-color: $theme-primary;
  }

  .navbar-light {
    .navbar-nav {
      .nav-item {
        .nav-link {
          color: rgba(255,255,255,.55);

          &.active, &:hover {
            color: #fff;
          }
        }
      }
    }

    .btn-outline-secondary {
      border-color: rgba(255,255,255,.55);
      color: rgba(255,255,255,.55);

      &:hover {
        background-color: rgba(255,255,255,.25);
        border-color: #fff;
        color: #fff;
      }
    }
  }

  .nav-link {
    color: $theme-primary;

    &:hover {
      color: $theme-primary-hover;
    }
  }

  .nav-pills {
    .nav-link {
      &.active {
        background-color: $theme-primary;
      }
    }
  }

  .navbar-brand {
    color: $white;
  }

  .btn-primary {
    background-color: $theme-primary;
    border-color: $theme-primary;

    &:hover {
      background-color: $theme-primary-hover;
      border-color: $theme-primary-hover;
    }
  }

  .btn-outline-primary {
    border-color: $theme-primary;
    color: $theme-primary;

    &:hover {
      color: #fff;
      background-color: $theme-primary;
    }
  }
}
